/* eslint-disable prefer-object-spread,no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import has from 'lodash/has';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import pull from 'lodash/pull';
import pullAt from 'lodash/pullAt';
import unset from 'lodash/unset';
import without from 'lodash/without';

import { TPayload } from '@monorepo/type';

import {
  BETSLIP_STATUS,
  BETSLIP_TYPE,
  ODD_MODE,
  PRICE_DELTA
} from '../constants';

type TBetInfo = {
  eventId: number;
  gameId: number;
  marketId: number;
  price: number;
  startTs: number;
  team1Name: string;
  team2Name: string;
  marketName: string;
  eventName: string;
  isBlocked: boolean;
  isDeleted: boolean;
  isLive: boolean;
  oldPrice: number;
  startPrice?: number;
  priceDelta: PRICE_DELTA.DOWN | PRICE_DELTA.UP;
};

type TState = {
  betsId: number[];
  gamesId: number[];
  betsInfo: TBetInfo[];
  oddMode: number;
  isMultipleMessageVisible: boolean;
  status: BETSLIP_STATUS;
  type: BETSLIP_TYPE;
  isOpened: boolean;
  isOddChanged: boolean;
  changedBets: number[];
  previousIndex: number;
  expBetsTime: number;
  betslipSuccessTime: number;
  singleSavedValue: Record<number, any>;
  multipleSavedValue: null | string;
  expressValues: Record<number, any>;
};

export const betslipSlice = createSlice({
  name: 'betslip',
  initialState: {
    status: BETSLIP_STATUS.IDLE,
    type: BETSLIP_TYPE.SINGLE,
    betsId: [],
    gamesId: [],
    betsInfo: [],
    oddMode: ODD_MODE.NO_CHANGE,
    isMultipleMessageVisible: false,
    isOddChanged: false,
    isOpened: false,
    changedBets: [],
    previousIndex: 0,
    expBetsTime: 0,
    betslipSuccessTime: 0,
    singleSavedValue: {},
    multipleSavedValue: null,
    expressValues: {}
  } as TState,
  reducers: {
    setBetInfo: (state, { payload }: TPayload<TBetInfo>) => {
      const { eventId, gameId } = payload;
      const existEventIndex = findIndex(state.betsInfo, { eventId });
      const existGameIndex = findIndex(state.betsInfo, { gameId });

      state.status = BETSLIP_STATUS.IDLE;

      if (state.betsId.length === 2 && existEventIndex !== -1) {
        state.type = BETSLIP_TYPE.SINGLE;
      }

      if (existGameIndex !== -1) {
        state.gamesId[existGameIndex] = gameId;
      }

      if (existEventIndex !== -1) {
        pullAt(state.betsInfo, existEventIndex);
        pullAt(state.betsId, existEventIndex);
        pullAt(state.gamesId, existEventIndex);

        unset(state.singleSavedValue, eventId);

        if (has(state.expressValues, gameId)) {
          unset(state.expressValues[gameId], eventId);

          if (isEmpty(state.expressValues[gameId])) {
            unset(state.expressValues, gameId);
          }
        }

        return state;
      }

      state.betsInfo.push({
        ...payload,
        isBlocked: false,
        isDeleted: false
      });
      state.betsId.push(eventId);
      state.gamesId.push(gameId);

      if (state.betsId.length === 2 && BETSLIP_TYPE.SINGLE) {
        state.type = BETSLIP_TYPE.MULTIPLE;
      }

      return state;
    },
    updateBet: (
      state,
      {
        payload
      }: TPayload<
        Partial<TBetInfo> &
          Pick<TBetInfo, 'eventId'> & { expressId: number | null }
      >
    ) => {
      const { eventId, gameId, price: newPrice, expressId = null } = payload;

      const betIndex = findIndex(state.betsInfo, { eventId });
      const bet = state.betsInfo[betIndex];

      const startPrice = bet.startPrice || bet.price;
      let priceDelta = '';

      if (newPrice) {
        priceDelta = startPrice > newPrice ? PRICE_DELTA.DOWN : PRICE_DELTA.UP;
      }

      if (startPrice === newPrice) {
        priceDelta = '';
      }

      merge(
        state.betsInfo[betIndex],
        payload,
        newPrice && {
          startPrice,
          oldPrice: bet.price === newPrice ? 0 : bet.price,
          priceDelta
        }
      );

      if (state.betsInfo[betIndex].priceDelta) {
        if (!includes(state.changedBets, eventId)) {
          state.changedBets.push(eventId);
        }
      } else {
        const eventIndex = indexOf(state.changedBets, eventId);
        if (eventIndex !== -1) {
          pullAt(state.changedBets, eventIndex);
        }
      }

      state.expressValues = {
        ...state.expressValues,
        [gameId!]: {
          ...state.expressValues[gameId!],
          [eventId]: expressId
        }
      };

      return state;
    },
    removeBet: (state, { payload }: TPayload<TBetInfo>) => {
      const { eventId, gameId } = payload;

      const isLastBet = state.betsId.length === 1;

      if (isLastBet && state.status === BETSLIP_STATUS.PROCESSING) {
        state.status = BETSLIP_STATUS.SUCCESS;
      }

      if (state.betsId.length === 2 && BETSLIP_TYPE.MULTIPLE) {
        state.type = BETSLIP_TYPE.SINGLE;
      }

      state.gamesId = without(state.gamesId, gameId);
      state.betsInfo = filter(state.betsInfo, (bet) => bet.eventId !== eventId);
      state.betsId = without(state.betsId, eventId);

      pull(state.changedBets, eventId);

      if (has(state.singleSavedValue, eventId)) {
        unset(state.singleSavedValue, eventId);
      }

      if (has(state.expressValues, gameId)) {
        unset(state.expressValues[gameId], eventId);

        if (isEmpty(state.expressValues[gameId])) {
          unset(state.expressValues, gameId);
        }
      }

      if (isEmpty(state.betsId)) {
        state.isOddChanged = false;
        state.singleSavedValue = {};
        state.expressValues = {};
        state.multipleSavedValue = null;
      }

      return state;
    },
    clearBets: (state) => {
      state.betsInfo = [];
      state.betsId = [];
      state.gamesId = [];
      state.type = BETSLIP_TYPE.SINGLE;
      state.changedBets = [];
      state.singleSavedValue = {};
      state.expressValues = {};
      state.multipleSavedValue = null;

      return state;
    },
    resetBetslip: (state) => {
      state.status = BETSLIP_STATUS.IDLE;
      return state;
    },
    setBetslipStatus: (state, { payload }) => {
      state.status = payload;
      return state;
    },
    setOddMode: (state, { payload }) => {
      state.oddMode = payload;
      return state;
    },
    setMultipleMessage: (state, { payload }) => {
      state.isMultipleMessageVisible = payload;
      return state;
    },
    setBetslipType: (state, { payload }) => {
      if (payload === BETSLIP_TYPE.MULTIPLE && state.betsId.length < 2) {
        state.isMultipleMessageVisible = true;
        return state;
      }

      state.type = payload;
      return state;
    },
    setBetslipOpened: (state, { payload }) => {
      state.isOpened = payload;
      return state;
    },
    setPreviousIndex: (state, { payload }) => {
      state.previousIndex = payload;
      return state;
    },
    setExpBetsTime: (state, { payload }) => {
      state.expBetsTime = payload;
      return state;
    },
    setBetslipSuccessTime: (state, { payload }) => {
      state.betslipSuccessTime = payload;
      return state;
    },
    resetBetslipSuccessTime: (state) => {
      state.betslipSuccessTime = 0;
      return state;
    },
    saveBetslipValue: (state, { payload }) => {
      const { type, value } = payload;
      if (type === BETSLIP_TYPE.MULTIPLE) {
        state.multipleSavedValue = value;
      } else {
        state.singleSavedValue = { ...state.singleSavedValue, ...value };
      }
      return state;
    }
  }
});

// Action creators are generated for each case reducer function
export const { actions } = betslipSlice;

export default betslipSlice.reducer;
