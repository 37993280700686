import React, { ElementRef, useEffect, useRef } from 'react';

import { useGetJWT, useSelectAuthData } from '@monorepo/auth';
import { Form, SubmitButton } from '@monorepo/forms';
import { useSearchParamsWithParsing } from '@monorepo/helpers';
import { useI18n } from '@monorepo/i18n';
import { SOCIAL_FRAME_NAME, SOCIAL_FRAME_NAME_MAP } from '@monorepo/messenger';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { Button, BUTTON_INTENT } from '@monorepo/ui-kit';

import DefaultFields from './components/DefaultFields';
import formFieldsMap from './formFieldsMap';

type FormData = {
  authToken: string;
  userId: string;
  partnerId: string;
  language: string;
  slug: string;
  externalId: string;
  readOnly: boolean;
};

type Props = {
  frameName: SOCIAL_FRAME_NAME;
  onSubmit: () => void;
};

const defaultValues = {
  authToken: '',
  userId: '',
  partnerId: '',
  language: '',
  slug: '',
  externalId: '',
  readOnly: false
};

const FrameForm: Component<Props> = ({ className, frameName, onSubmit }) => {
  const formRef = useRef<ElementRef<typeof Form>>(null);
  const frameRef = useRef<HTMLIFrameElement>(
    document.getElementById(frameName) as HTMLIFrameElement
  );
  const { searchParams, setSearchParamsObject } = useSearchParamsWithParsing();
  const authData = useSelectAuthData();
  const { data: jweAuthToken } = useGetJWT();
  const { language } = useI18n();
  const { siteId } = useSiteSettings();

  const values = {
    authToken: searchParams.authToken || jweAuthToken || '',
    userId: searchParams.userId || authData?.ownerId || authData?.userId || '',
    partnerId: searchParams.partnerId || siteId,
    language: searchParams.language || language,
    slug: searchParams.slug || '',
    externalId: searchParams.externalId || '',
    readOnly: searchParams.readOnly || false,
    defaultView: searchParams.defaultView || '',
    contactId: searchParams.contactId || ''
  };

  const FieldsComponent = formFieldsMap[frameName];

  const handleSubmit = (data: FormData) => {
    frameRef.current?.contentWindow?.postMessage(
      {
        type: SOCIAL_FRAME_NAME_MAP[frameName],
        data
      },
      '*'
    );
    setSearchParamsObject(data);
    onSubmit();
  };

  const handleResetForm = () => {
    formRef.current?.reset();
  };

  useEffect(() => {
    const node = document.getElementById(frameName);
    if (node) {
      frameRef.current = node as HTMLIFrameElement;
    }
  }, [frameName]);

  return (
    // @ts-ignore
    <Form<FormData>
      ref={formRef}
      onSubmit={handleSubmit}
      formSettings={{ defaultValues, values, mode: 'onSubmit' }}
      className={className}
    >
      <DefaultFields />
      {FieldsComponent && (
        <>
          <br />
          <span>Additional params</span>
          <FieldsComponent />
        </>
      )}
      <SubmitButton mode="onSubmit" checkDirty={false}>
        Send post message
      </SubmitButton>
      <Button intent={BUTTON_INTENT.tertiary} onClick={handleResetForm}>
        Reset
      </Button>
    </Form>
  );
};

export default FrameForm;
