import { TGetUserRequest, TPostUserProfilesRequest } from '../../types/requests';
import {
  TGetUserResponse,
  TPostUserProfilesResponse
} from '../../types/responses';

import messengerApi from './index';

const messengerUsersApi = messengerApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<TGetUserResponse, TGetUserRequest>({
      query: ({ token, username = '' }) => ({
        method: 'GET',
        url: `/users/${username}`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    }),
    postUserProfiles: build.query<
      TPostUserProfilesResponse,
      TPostUserProfilesRequest
    >({
      query: ({ token, list }) => ({
        method: 'POST',
        url: `/users/profiles`,
        body: list,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    })
  })
});

export const { useGetUserQuery, usePostUserProfilesQuery } = messengerUsersApi;

export default messengerUsersApi;
