import React, { Component, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import { googleAnalytics } from '@monorepo/helpers';
import { NAMESPACES } from '@monorepo/i18n';
import { Spinner } from '@monorepo/ui-kit';

import useAppActions from '../../hooks/useAppActions';
import useSelectErrorsCount from '../../store/selectors/errorsSelector';

import styles from './index.module.css';

function DisplayOnError({ t, error }) {
  const count = useSelectErrorsCount();
  const { incrementErrorCount, resetErrorCount } = useAppActions();

  const translate = (key: string) => t(key, { ns: NAMESPACES.COMMON });

  useEffect(() => {
    if (count < 5) {
      if (count === 1) {
        // ga js-error
        const ga = googleAnalytics();
        ga.dispatch({
          event: ga.event.jsError,
          eventParam: {
            event_category: 'js'
          },
          event_options: {
            message: error?.message,
            data: error
          }
        });
      }
      setTimeout(() => {
        incrementErrorCount(undefined);
        window.location.reload();
      }, 2000);
    }
  }, [count]);
  const goToMain = () => resetErrorCount(undefined);

  return (
    <div className={styles.wrap}>
      <img alt="" src="/images/logo.svg" className={styles.img} />
      <div className={styles.text}>{translate('error.something_wrong')}</div>

      {(count >= 5 && (
        <>
          <div className={styles.sub}>{translate('error.try_use')}</div>
          <a href="/" className={styles.link} onClick={goToMain}>
            {translate('error.main_page')}
          </a>
        </>
      )) || <Spinner className={styles.spinner} />}
    </div>
  );
}

class ErrorBoundary extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      isError: false,
      error: {}
    };
  }

  // @ts-ignore
  componentDidCatch(e) {
    // eslint-disable-next-line no-console
    console.warn(e);
    this.setState({ isError: true, error: e });
  }

  render() {
    const { children, t } = this.props;
    // @ts-ignore
    const { isError, error } = this.state;
    const showError =
      import.meta.env.VITE_APP_SHOW_ERROR_MESSAGE === 'true' && isError;
    if (showError) {
      return <DisplayOnError t={t} error={error} />;
    }
    return children;
  }
}

export default withTranslation()(ErrorBoundary);
