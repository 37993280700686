import React from 'react';
import { Link } from 'react-router-dom';

import { EditorAvatar, LIKE_TYPE } from '@monorepo/common';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { Box, FLEX_ALIGN, FlexBox, PreloadImage } from '@monorepo/ui-kit';

import { TPromotion, TPromotionsListConfig } from '../../types';
import PromotionInfo from '../PromotionInfo';
import PromotionPlaceholder from '../PromotionPlaceholder';
import PromotionTitle from '../PromotionTitle';

import styles from './index.module.css';

type Props = Pick<TPromotion, 'id' | 'title' | 'src' | 'eventDate'> &
  TPromotionsListConfig;

const cmsHost = import.meta.env.VITE_APP_CMS_IMAGES_HOST;

const PromotionCard: Component<Props> = ({
  id,
  title,
  src,
  eventDate,
  withLike,
  withComment,
  withStream,
  withShare,
  withFavorite,
  isPromo,
  className = ''
}) => {
  const { generalRoutes } = useSiteSettings();
  const { promo } = generalRoutes;

  return (
    <Box className={`${styles.wrap} ${className}`}>
      <Link to={`${promo}/${id}`} className={styles.link} />
      <PreloadImage
        src={`${cmsHost}${src}`}
        alt={title}
        className={isPromo ? styles.promoBackground : styles.background}
        imgClassName={isPromo ? styles.promoImg : styles.img}
      >
        <PromotionPlaceholder />
      </PreloadImage>
      {!isPromo && (
        <PromotionInfo
          className={styles.info}
          id={id}
          type={LIKE_TYPE.WALL}
          eventDate={eventDate}
          withShare={withShare}
          withFavorite={withFavorite}
          withComment={withComment}
          withLike={withLike}
          withStream={withStream}
          href={`${promo}/${id}`}
          title={title}
        />
      )}
      {isPromo ? (
        <FlexBox align={FLEX_ALIGN.start} className={styles.titleWrap}>
          <PromotionTitle className={styles.title} title={title} />
        </FlexBox>
      ) : (
        <FlexBox align={FLEX_ALIGN.center} className={styles.titleWrap}>
          <EditorAvatar className={styles.avatar} />
          <PromotionTitle className={styles.title} title={title} />
        </FlexBox>
      )}
    </Box>
  );
};

export default PromotionCard;
