import get from 'lodash/get';

export const normalizeBetInfoResponse = (rawResponse: any) => {
  const bet = get(rawResponse, 'details', null);

  if (!bet) {
    return null;
  }
  const events = get(rawResponse, 'details.SelectionList', []).map((event) => {
    return {
      betId: bet.Id,
      selectionId: event.SelectionId,
      coeficient: event.Price,
      gameStartDate: Math.floor(new Date(event.StartTime).getTime() / 1000),
      order: event.Order,
      isLive: event.IsLive,
      gameId: event.MatchId,
      team1: event.HomeTeamName,
      team2: event.AwayTeamName,
      competitionName: event.CompetitionName,
      gameName: event.MatchName,
      matchInfo: event.MatchInfo,
      info: event.Info,
      marketName: event.DisplayMarketName,
      gameInfo: event.SelectionName,
      eventName: event.DisplaySelectionName,
      sportName: event.SportName,
      sportIndex: event.SportAlias,
      regionName: event.RegionName,
      matchDisplayId: event.MatchNum,
      stateId: event.State,
      stateName: event.StateName
    };
  });

  return {
    betType: bet.TypeName,
    oddTypeString: '',
    currency: bet.CurrencyId,
    amount: bet.Amount,
    dateTime: Math.floor(new Date(bet.Created).getTime() / 1000),
    oddType: '',
    id: bet.Id,
    oddCoeficient: bet.Price,
    stateId: bet.StateId,
    stateName: bet.StateName,
    payout: bet.TaxAmount,
    possibleWin: bet.PossibleWin,
    type: bet.TypeId,
    events
  };
};
