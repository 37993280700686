import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const UnreadMessageNotification = lazy(
  () => import('./UnreadMessageNotification')
);

export default (props: ComponentProps<typeof UnreadMessageNotification>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <UnreadMessageNotification {...props} />
  </Suspense>
);
