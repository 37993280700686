import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const BetDetails = lazy(() => import('./BetDetails'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BetDetails {...props} />
  </Suspense>
);
