import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const avatarApi = createApi({
  reducerPath: 'avatarApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1'
  }),
  endpoints: (build) => ({
    avatarList: build.query<string[], void>({
      query: () => ({
        method: 'GET',
        url: `ms/default_avatars`
      }),
      transformResponse(data: {
        response: string[];
        status: string;
      }): string[] {
        return data.response;
      }
    })
  })
});

export const { useAvatarListQuery } = avatarApi;

export default avatarApi;
