import BaseSocketClient from '../BaseSocketClient';
import openSocketConnection from '../openSocketConnection';

// eslint-disable-next-line import/prefer-default-export
export const openSocketSWARM = () =>
  openSocketConnection({
    host: import.meta.env.VITE_APP_WEBSOCKET_HOST || '',
    requestIdProperty: 'rid',
    ClientClass: BaseSocketClient
  });
