import { createSlice } from '@reduxjs/toolkit';

const recaptchaSlice = createSlice({
  name: 'recaptcha',
  initialState: {
    isRecaptchaValidated: false
  },
  reducers: {
    setValidationRecaptcha: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.isRecaptchaValidated = action.payload;

      return state;
    }
  }
});

export const { actions } = recaptchaSlice;
export default recaptchaSlice;
