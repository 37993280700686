import React, { useEffect, useRef } from 'react';

import { Component } from '@monorepo/type';

import { PAY_METHOD } from '../../../constants';
import { TPaymentAction } from '../../../types';

type Props = {
  formData: TPaymentAction;
  url: string;
  quickDeposit?: boolean;
};

const PaymentSubmitForm: Component<Props> = ({ formData, url, quickDeposit = false }) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (formData.method === PAY_METHOD.GET) {
      if (quickDeposit) {
        window.open(url, '_blank');
      } else {
        window.location.replace(url);
      }
    } else {
      formRef.current?.submit();
    }
  }, []);

  return (
    <form ref={formRef} action={formData.action} method={formData.method}>
      {(formData.fields || []).map(({ name, value }) => (
        <input key={name} name={name} value={value} type="hidden" />
      ))}
    </form>
  );
};

export default PaymentSubmitForm;
