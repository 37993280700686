import { camelizeKeys } from 'humps';

import get from 'lodash/get';

import { TBetEvent, TBets, TBetsHistory } from '../../../types';

const betsHistoryAdapter = (response: TBetsHistory): TBets[] => {
  const result = get(response, 'bets', null);
  if (!result) {
    return [];
  }
  return result.map(
    ({
      currency,
      amount,
      date_time,
      odd_type,
      id,
      k,
      outcome,
      possible_win,
      payout,
      type,
      events,
      cash_out
    }) => {
      const EVENTS = events.map((event) => camelizeKeys(event) as TBetEvent);

      return {
        currency,
        amount,
        dateTime: date_time,
        oddType: odd_type,
        id,
        oddCoeficient: k,
        outcome,
        payout,
        possibleWin: possible_win,
        type,
        events: EVENTS,
        cashOut: cash_out
      };
    }
  );
};

export default betsHistoryAdapter;
