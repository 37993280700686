import isNil from 'lodash/isNil';

import { TPlayerInformationGetArgs } from '../../../../types';

const getPlayerProfileSettings = () => ({
  query: (args: TPlayerInformationGetArgs) => {
    let url = `/public_profile/${args.userId}`;
    if (!isNil(args.partnerId)) {
      url += `?partner_id=${args.partnerId}`;
    }

    return {
      url,
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    };
  }
});

export default getPlayerProfileSettings;
