import React, { useRef } from 'react';

import { getSearchString, useSearchParamsWithParsing } from '@monorepo/helpers';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Iframe
} from '@monorepo/ui-kit';

import styles from './index.module.css';

const host = import.meta.env.VITE_APP_MESSENGER_IFRAME_HOST || '/iframeV2';

const TestMessengerFrame = () => {
  const { searchParams } = useSearchParamsWithParsing();
  const { frameName, theme } = searchParams;
  const ref = useRef<HTMLIFrameElement>(null);

  if (frameName) {
    return (
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
        <Iframe
          className={styles.iframe}
          src={`${host}/${frameName}${getSearchString({ theme })}`}
          ref={ref}
          // @ts-ignore
          allowtransparency="true"
          allow="clipboard-write"
          id={frameName.toString()}
        />
      </FlexBox>
    );
  }

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.wrap}
    >
      iframe is not initialized
    </FlexBox>
  );
};

export default TestMessengerFrame;
