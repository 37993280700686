import React, { useMemo } from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { CustomFC } from '@monorepo/type';
import { getTooltipDataAttributes } from '@monorepo/ui-kit';

import { IconProps } from '../../types';

import '../../fonts/fronticon.css';
import styles from './index.module.css';

const Icon: CustomFC<IconProps> = ({
  name = '',
  categoryIcon = '',
  className = '',
  title = '',
  ...props
}) => {
  const { isDesktop } = useDeviceDetector();
  const IconName = categoryIcon
    ? `${styles[categoryIcon]} icon-${categoryIcon}-${name}`
    : `icon-${name}`;
  const classes = `${styles.icon} ${IconName} ${className}`;

  const tooltipAttrs = useMemo(
    () =>
      getTooltipDataAttributes({
        content: title,
        hidden: isDesktop || !title
      }),
    [isDesktop, title, name]
  );

  return <i title={title} className={classes} {...props} {...tooltipAttrs} />;
};

export default Icon;
