import React from 'react';

import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../constants';
import usePaymentServiceData from '../../../hooks/usePaymentServiceData';

import Item from './components/Item';

import styles from './index.module.css';

type Props = {
  setDepositSystem: React.Dispatch<React.SetStateAction<string | any>>;
  serviceType: SERVICE_TYPES;
  activeSystem?: string;
  isMobile?: boolean;
  imageFromCms?: boolean;
};

const CardSystem: Component<Props> = ({
  setDepositSystem,
  serviceType,
  activeSystem,
  imageFromCms = false
}) => {
  const { isFetching, listData } = usePaymentServiceData(serviceType);

  if (isFetching) {
    return null;
  }

  return (
    <Box
      className={styles.wrapper}
    >
      {listData.map((id) => (
        <Item
          serviceType={serviceType}
          imageFromCms={imageFromCms}
          id={id}
          className={`${styles.item} ${
            id === activeSystem ? styles.active : ''
          }`}
          key={id}
          onClick={setDepositSystem}
        />
      ))}
    </Box>
  );
};

export default CardSystem;
