import React from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { SEARCH_STRING_PARAMS } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import useHeaderSearch from './hooks/useHeaderSearch';

import styles from './index.module.css';

const HeaderSearch = () => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();

  const { searchPageLink } = useHeaderSearch();
  const location = useLocation();
  const { generalRoutes } = useSiteSettings();
  const { searchSports, searchCasino, prematchPopular, casino } = generalRoutes;
  const [searchParams] = useSearchParams();

  const isSearchPage =
    location.pathname === searchSports || location.pathname === searchCasino;

  const pathToRedirect = () => {
    if (isSearchPage) {
      if (searchParams.get(SEARCH_STRING_PARAMS.redirectUrl) !== null) {
        return searchParams.get(SEARCH_STRING_PARAMS.redirectUrl);
      }

      return location.pathname === searchCasino ? casino : prematchPopular;
    }

    return `${searchPageLink}?${
      SEARCH_STRING_PARAMS.redirectUrl
    }=${encodeURIComponent(location.pathname)}`;
  };

  return (
    <NavLink className={styles.link} to={pathToRedirect()}>
      <FlexBox
        direction={FLEX_DIRECTION.column}
        justify={FLEX_JUSTIFY.center}
        align={FLEX_ALIGN.center}
      >
        <Icon
          name={isSearchPage && isDesktop ? 'close_0' : 'search_1'}
          className={styles.icon}
          title=""
        />
        {!isDesktop && (
          <EllipsisText className={styles.title}>
            {t('navigation.search')}
          </EllipsisText>
        )}
      </FlexBox>
    </NavLink>
  );
};
export default HeaderSearch;
