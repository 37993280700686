import React from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';
import isEmpty from 'lodash/isEmpty';

import { EmptyState } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  useGetLastWinnersQuery,
  useGetTopWinnersQuery
} from '@monorepo/tournaments';
import { CustomFC } from '@monorepo/type';
import { Box, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import CardsSkeleton from '../../../../common/CardsSkeleton';
import useGetWinnersList from '../../../../hooks/useGetWinnersList';

import WinnersList from './components/WinnersList';

import styles from './index.module.css';

type TWinners = {
  winnersAmount: number;
};

const Winners: CustomFC<TWinners> = ({ winnersAmount }) => {
  const { isDesktop } = useDeviceDetector();
  const { t } = useI18n(NAMESPACES.CASINO);

  const { fullWinnersData: topWinners, winnersFetching: isFetching1 } =
    useGetWinnersList(useGetTopWinnersQuery, winnersAmount);
  const { fullWinnersData: lastWinners, winnersFetching: isFetching2 } =
    useGetWinnersList(useGetLastWinnersQuery, winnersAmount);

  if (isFetching1 || isFetching2) {
    return (
      <Box className={styles.skeletonWrap}>
        <CardsSkeleton />
      </Box>
    );
  }

  if (isEmpty(lastWinners) && isEmpty(topWinners)) {
    return (
      <EmptyState
        icon="winner_icon-1"
        iconClassName={styles.icon}
        title={t('casino.empty.winners')}
      />
    );
  }

  const LastWinners = () =>
    isEmpty(lastWinners) ? (
      <EmptyState
        icon="winner_icon-1"
        iconClassName={styles.icon}
        title={t('casino.empty.winners')}
      />
    ) : (
      <WinnersList winners={lastWinners} title="last_winners" />
    );

  const TopWinners = () =>
    isEmpty(topWinners) ? (
      <EmptyState
        icon="winner_icon-1"
        iconClassName={styles.icon}
        title={t('casino.empty.winners')}
      />
    ) : (
      <WinnersList winners={topWinners} title="top_winners" />
    );

  return (
    <FlexBox className={styles.wrap} justify={FLEX_JUSTIFY.between}>
      {isDesktop && <LastWinners />}
      <TopWinners />
    </FlexBox>
  );
};

export default Winners;
