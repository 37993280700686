import React from 'react';
import CoreDatePicker from 'react-datepicker';
import { useDeviceDetector } from '@bc-betkit/device-detector';
import parse from 'date-fns/parse';

import { DATE_FORMAT } from '@monorepo/common';

import { FIELD_TONE } from '../../../constants';
import { TDatePickerProps } from '../../../types';
import Input from '../Input';

import './react-datepicker.css';
import styles from './index.module.css';

const DatePicker = ({
  value = '',
  disabled = false,
  toneType = FIELD_TONE.dark,
  iconName = 'calendar_today',
  formatString = DATE_FORMAT.SHORT,
  locale = '',
  ...props
}: TDatePickerProps) => {
  const { isDesktop } = useDeviceDetector();
  return (
    <CoreDatePicker
      {...props}
      disabled={disabled}
      locale={locale}
      calendarClassName={`datepicker-${toneType}`}
      selected={value ? parse(value, formatString, new Date()) : null}
      onFocus={(e) => !isDesktop && e.target.blur()}
      customInput={
        <Input
          className={styles.input}
          toneType={toneType}
          iconName={iconName}
          autoComplete="none"
        />
      }
      dateFormat={formatString}
      popperClassName={styles.popper}
    />
  );
};

export default DatePicker; // todo icon calendar
