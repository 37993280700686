import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Swiper } from 'swiper';

import { TGame } from '@monorepo/casino/src/types';
import { LikesProvider } from '@monorepo/common';
import { useDebounce } from '@monorepo/helpers';
import { useComponentsConfig } from '@monorepo/page';
import { Component } from '@monorepo/type';
import { Box, Carousel, PaddingWrapper, SliderShadows } from '@monorepo/ui-kit';

import SuggestedSliderHeader from '../../common/SuggestedSliderHeader';
import { TSportHomeConfig } from '../../types';

import SportsSuggestedSkeleton from './components/SportsSliderSkeleton/SportsSuggestedSkeleton';
import getSportSliderItemByType from './helpers';
import { SportsSliderItemType } from './types';

import styles from './index.module.css';

type TProps = TSportHomeConfig & {
  itemType: SportsSliderItemType;
  games: Array<TGame>;
  isFetching?: boolean;
  isUninitialized?: boolean;
};

const SportsSlider: Component<TProps> = ({
  showMoreButton,
  isWithButtons,
  isViewAll,
  title,
  itemType,
  games,
  isFetching,
  isUninitialized
}) => {
  const { withScroll } = useComponentsConfig<{
    withScroll?: boolean;
  }>();

  const likeIds = useMemo(() => games?.map(({ id }) => `${id}`) || [], [games]);
  const suggestedRef = useRef<Swiper | null>(null);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const onNext = useCallback(() => {
    if (suggestedRef?.current) {
      suggestedRef.current.slideNext();
    }
  }, [suggestedRef.current]);

  const onPrev = useCallback(() => {
    if (suggestedRef?.current) {
      suggestedRef.current.slidePrev();
    }
  }, [suggestedRef.current]);

  const onSlideChange = useDebounce((swiper: Swiper) => {
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, 500);

  useEffect(() => {
    if (suggestedRef.current) {
      const { isBeginning, isEnd: isLastSlide } = suggestedRef.current;
      if (isLastSlide && isBeginning) {
        setIsStart(true);
        setIsEnd(true);
      }
    }
  }, [suggestedRef.current]);

  if (isFetching || isUninitialized) {
    return (
      <SportsSuggestedSkeleton
        isWithButtons={isWithButtons}
        rows={1}
        limit={10}
      />
    );
  }

  const SlideItem = getSportSliderItemByType(itemType);

  const slides = (games || []).map(
    ({
      id,
      sportId,
      competitionId,
      regionId,
      regionAlias,
      sportAlias,
      isLive
    }: any) => ({
      id,
      component: () => (
        <SlideItem
          key={id}
          sportId={sportId}
          regionId={regionId}
          competitionId={competitionId}
          gameId={id}
          showMoreButton={showMoreButton}
          regionAlias={regionAlias}
          sportAlias={sportAlias}
          isLive={isLive}
        />
      )
    })
  );

  return (
    <LikesProvider list={likeIds}>
      <Box className={styles.wrapper}>
        <PaddingWrapper isOnlyMobile className={styles.header}>
          <SuggestedSliderHeader
            title={title}
            isViewAll={isViewAll}
            isWithButtons={isWithButtons}
            isStart={isStart}
            isEnd={isEnd}
            onPrev={onPrev}
            onNext={onNext}
            count={games ? games.length : 0}
          />
        </PaddingWrapper>
        <SliderShadows
          className={styles.container}
          isWithPrev={!isStart && isWithButtons}
          isWithNext={!isEnd && isWithButtons}
        >
          <Carousel
            slides={slides}
            className={styles.wrap}
            slideClassName={styles.item}
            useSwiper
            params={{
              ...(withScroll && {
                scrollbar: {
                  dragClass: styles.scrollbarDrag,
                  draggable: true
                }
              }),
              slidesPerView: 1,
              spaceBetween: 12,
              autoplay: {
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
                delay: 6000
              },
              breakpoints: {
                440: {
                  slidesPerView: 'auto',
                  spaceBetween: 12
                },
                1336: {
                  spaceBetween: 12,
                  slidesPerView: 'auto',
                  pagination: false
                }
              },
              freeMode: {
                enabled: true,
                momentumBounce: false,
                momentumRatio: 0.2,
                minimumVelocity: 0.01,
                momentum: true
              },
              lazy: true,
              onSwiper: (ref: Swiper) => {
                suggestedRef.current = ref;
              },
              onSlideChange,
              onReachEnd: () => {
                setIsStart(false);
                setIsEnd(true);
              },
              onReachBeginning: () => {
                setIsStart(true);
                setIsEnd(false);
              }
            }}
          />
        </SliderShadows>
      </Box>
    </LikesProvider>
  );
};

export default SportsSlider;
