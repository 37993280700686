import React, { useState, useCallback, useEffect, createContext } from 'react';

import disableBodyScroll from '../../utils/disableBodyScroll';

interface ModalContextType {
  addLayer: () => void;
  removeLayer: () => void;
}
export const ModalContext = createContext<ModalContextType | null>(null);

export const ModalProvider = ({ children }) => {
  const [layers, setLayers] = useState([]);
  const addLayer = useCallback(
    (layer) => setLayers((prevLayers) => [...prevLayers, layer]),
    []
  );
  const removeLayer = useCallback(
    (layer) => setLayers((prevLayers) => prevLayers.filter((l) => l !== layer)),
    []
  );

  useEffect(() => {
    disableBodyScroll(layers);
  }, []);

  useEffect(() => {
    disableBodyScroll(layers);
  }, [layers]);

  return (
    <ModalContext.Provider value={{ addLayer, removeLayer }}>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
