import React from 'react';
import { useParams } from 'react-router-dom';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import {
  EmptyState,
  LIKE_TYPE,
  LikesProvider,
  useScrollToTop
} from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  Box,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FlexBox,
  LinkButton
} from '@monorepo/ui-kit';

// import BlogAnother from '../../common/BlogAnother';
// import BlogTopAuthors from '../../common/BlogTopAuthors';
import BlogContent from '../../common/BlogContent';
import useGetBlog from '../../hooks/useGetBlog';

import styles from './index.module.css';

const Blog = () => {
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const { isDesktop } = useDeviceDetector();

  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { id } = useParams<Record<string, string>>();
  const { data } = useGetBlog(id);
  const likeIds = [`${id}`];

  useScrollToTop();

  if (!data) {
    return (
      <EmptyState
        icon="favorites-0"
        title={t('blogs.empty')}
        iconClassName={styles.emptyIcon}
        className={styles.empty}
      />
    );
  }

  return (
    <LikesProvider list={likeIds}>
      <FlexBox className={styles.wrap}>
        <BlogContent blog={data} className={styles.content} />
        {isDesktop ? (
          <FlexBox direction={FLEX_DIRECTION.column} className={styles.side}>
            <FlexBox
              className={styles.add}
              direction={FLEX_DIRECTION.column}
              align={FLEX_ALIGN.center}
            >
              <Box className={styles.addText}>{t('blogs.write.blog')}</Box>
              <Icon name="message-add" className={styles.addIcon} />
              <LinkButton
                to={`${blogs}/add`}
                className={styles.addLink}
                size={BUTTON_SIZE.h40}
              >
                {t('blogs.add.new.blog')}
              </LinkButton>
            </FlexBox>
            {/* <BlogTopAuthors /> */}
            {/* <BlogAnother /> */}
          </FlexBox>
        ) : (
          <LinkButton
            to={`${blogs}/add`}
            toneType={FIELD_TONE.light}
            intent={BUTTON_INTENT.primary}
            className={styles.addMobile}
            size={BUTTON_SIZE.h40}
          >
            <Icon name="message-add" className={styles.addMobileIcon} />
          </LinkButton>
        )}
      </FlexBox>
    </LikesProvider>
  );
};
export default Blog;
