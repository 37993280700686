import uniqueId from 'lodash/uniqueId';
import ReconnectingWebSocket, { Options } from 'reconnecting-websocket';

import { googleAnalytics } from '@monorepo/helpers';

const RECONNECT_NUMBER = 10;

export type TSocketClientConstructor = {
  url: string;
  requestIdProperty?: string;
  isPing?: boolean;
  withRequestId?: boolean;
  pingDelay?: number;
  pingMessage?: Record<string, any>;
  maxReconnectionAttempts?: number;
  connectionHandler?: () => void;
  socketParams?: Omit<Options, 'maxReconnectionDelay' | 'maxRetries'>;
};

class SocketClient {
  private isPing: boolean;

  private readonly withRequestId: boolean;

  private pingDelay: number;

  protected readonly requestIdProperty: string;

  private readonly connectionHandler: (
    state: number,
    type: string,
    event: any
  ) => void;

  public connection: ReconnectingWebSocket | null;

  private pingIntervalInstance?: ReturnType<typeof setInterval>;

  protected pingMessage: Record<string, any>;

  private forceReconnectionCountdown: number = RECONNECT_NUMBER;

  private connectionTimeStart: number;

  public reconnect = () => {
    // ga js-reconnect
    if (this.connection && this.isOpen && this.forceReconnectionCountdown > 0) {
      this.connection.reconnect();
      this.forceReconnectionCountdown -= 1;
    }
  };

  constructor({
    url = '',
    connectionHandler = () => {},
    isPing = false,
    withRequestId = true,
    requestIdProperty = 'rid',
    pingDelay = 5000,
    pingMessage = { command: 'ping' },
    socketParams = {},
    maxReconnectionAttempts = RECONNECT_NUMBER
  }: TSocketClientConstructor) {
    this.connection = new ReconnectingWebSocket(url, undefined, {
      maxReconnectionDelay: 5000,
      maxRetries: maxReconnectionAttempts,
      debug: false,
      ...socketParams
    });
    this.connectionTimeStart = new Date().getTime();
    this.isPing = isPing;
    this.withRequestId = withRequestId;
    this.pingDelay = pingDelay;
    this.requestIdProperty = requestIdProperty;
    this.pingMessage = pingMessage;
    this.forceReconnectionCountdown = maxReconnectionAttempts;
    // this.initMessages = messages;
    this.connectionHandler = connectionHandler;
    if (this.connection) {
      this.connection.onclose = this.onClose;
      this.connection.onerror = this.onError;
      this.connection.onopen = this.onOpen;
      this.connection.onmessage = this.onMessage;
    }
  }

  subscribers = new Map();

  nonSentMessages = new Map();

  isOpen = false;

  isFirstOpen = true;

  sentMessages = new Map();

  reconnectionCallbacks = new Map();

  onInit = () => new Promise<void>((resolve) => resolve());

  set setOnInit(callback: () => Promise<any>) {
    this.onInit = callback;
  }

  defaultConnectionHandler = (type: string, event?: any) => {
    if (this.connection && import.meta.env.NODE_ENV !== 'test') {
      console.log(`connection ${type}`, this.connection.url); // eslint-disable-line
      this.connectionHandler(this.connection.readyState, type, event);
    }
  };

  onOpen = () => {
    const time = new Date().getTime() - this.connectionTimeStart;
    // ga  js-connection-time
    const ga = googleAnalytics();
    ga.dispatch({
      event: ga.event.jsConectTime,
      eventParam: {
        event_category: 'js'
      },
      event_options: {
        url: this.connection?.url,
        time
      }
    });
    this.isOpen = true;
    this.defaultConnectionHandler('open');
    this.nonSentMessages.forEach(this.send);
    if (!this.withRequestId) {
      this.nonSentMessages = new Map();
    }
    this.onInit()?.then(() => {
      this.forceReconnectionCountdown = RECONNECT_NUMBER;
      if (!this.isFirstOpen) {
        // ga js-reconnect
        ga.dispatch({
          event: ga.event.jsSwarmReconnect,
          eventParam: {
            event_category: 'js'
          },
          event_options: {
            url: this.connection?.url
          }
        });
        this.reconnectionCallbacks.forEach((callback) => callback());
      }
    });

    if (this.isPing) {
      this.pingIntervalInstance = setInterval(
        () => this.send(this.pingMessage),
        this.pingDelay
      );
    }
  };

  onClose = (event: any) => {
    this.isOpen = false;
    this.isFirstOpen = false;
    if (this.isPing && this.pingIntervalInstance) {
      clearInterval(this.pingIntervalInstance);
    }

    this.defaultConnectionHandler('close', event);
  };

  onError = (error: any) => {
    // ga  js-error
    const ga = googleAnalytics();
    ga.dispatch({
      event: ga.event.jsError,
      eventParam: {
        event_category: 'js'
      },
      event_options: {
        message: error?.message,
        data: JSON.stringify(error)
      }
    });
    this.defaultConnectionHandler('error', error);
  };

  handleMessage = (messageData: Record<string, any>) => {
    const { data } = messageData;
    this.subscribers.forEach((callback, subid) => {
      if (Object.hasOwn(data, subid)) {
        callback(data[subid]);
      }
    });
  };

  checkPong = (data: string) => data === 'pong';

  onMessage = (message: Record<string, any>) => {
    try {
      const { data } = message;
      if (this.checkPong(data)) return;
      const parsedData = JSON.parse(data);
      (Array.isArray(parsedData) ? parsedData : [parsedData]).forEach((mes) => {
        if (this.sentMessages.has(parsedData[this.requestIdProperty])) {
          this.sentMessages
            .get(parsedData[this.requestIdProperty])
            .resolve(parsedData);
          this.sentMessages.delete(parsedData[this.requestIdProperty]);
        } else {
          this.handleMessage(mes);
        }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e);
      // ga  js-error
      const ga = googleAnalytics();
      ga.dispatch({
        event: ga.event.jsError,
        eventParam: {
          event_category: 'js'
        },
        event_options: {
          message: (e as any)?.message,
          data: {
            message
          }
        }
      });
    }
  };

  private createPromise = (message: Record<string, any>) =>
    new Promise((resolve, reject) => {
      try {
        // send message directly to socket
        this.connection?.send(JSON.stringify(message));
        // save rid with resolve callback to fulfill promise after receiving answer
        if (!this.sentMessages.has(message[this.requestIdProperty])) {
          this.sentMessages.set(message[this.requestIdProperty], {
            resolve,
            reject
          });
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    });

  send = (baseMessage: Record<string, any> | Promise<unknown>) => {
    // if message is promise - return it. It's a message from preconnection
    if (typeof baseMessage?.then === 'function') {
      return baseMessage as Promise<unknown>;
    }

    let message =
      typeof baseMessage === 'function' ? baseMessage() : baseMessage;
    if (this.withRequestId) {
      message = {
        ...message,
        [this.requestIdProperty]:
          message[this.requestIdProperty] || uniqueId('rid-')
      };
    }
    const messagePromise = this.createPromise(message);

    if (!this.connection || !this.isOpen) {
      if (!this.isFirstOpen) {
        this.nonSentMessages.set(
          message[this.requestIdProperty],
          messagePromise
        );
      }
    }
    return messagePromise;
  };

  subscribe = (
    subscriptionId: string,
    callback: (data: any) => void,
    onReconnect?: () => void
  ) => {
    if (!this.subscribers.has(subscriptionId)) {
      this.subscribers.set(subscriptionId, callback);
      if (onReconnect) {
        this.reconnectionCallbacks.set(subscriptionId, onReconnect);
      }
    }
  };

  unsubscribe = (subid: string) => {
    this.subscribers.delete(subid);
    this.reconnectionCallbacks.delete(subid);
  };
}

export default SocketClient;
