import React, { useMemo } from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { useIsAuthenticated } from '@monorepo/auth';
import { transformMenuItems, useGetMenu } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  EllipsisText,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Portal,
  Shutter,
  useShutterRef
} from '@monorepo/ui-kit';

import { BurgerMenuProps } from '../../types';

import CloseButton from './components/CloseButton';
import Menu from './components/Menu';

import styles from './index.module.css';

const BurgerMenu: ComponentWithConfig<BurgerMenuProps> = ({ config }) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();
  const { generalRoutes } = useSiteSettings();
  const { contentPage } = generalRoutes || {};
  const { menuId, side = '' } = config;
  const { shutterRef, setShow } = useShutterRef();
  const { isLoading, data: cmsMenuItems = [] } = useGetMenu(menuId);
  const { isAuthenticated } = useIsAuthenticated();

  const burgerItems = useMemo(
    () => transformMenuItems(cmsMenuItems, isAuthenticated, contentPage),
    [cmsMenuItems]
  );

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Button
        intent={BUTTON_INTENT.transparent}
        isOnlyIcon
        onClick={setShow}
        className={styles.button}
      >
        <FlexBox
          direction={FLEX_DIRECTION.column}
          justify={FLEX_JUSTIFY.center}
        >
          <Icon name="menu_2" title="" />
          {!isDesktop && (
            <EllipsisText className={styles.title}>
              {t('navigation.menu')}
            </EllipsisText>
          )}
        </FlexBox>
      </Button>
      <Portal>
        {/* @ts-ignore */}
        <Shutter side={side} ref={shutterRef} wrapperClassName={styles.wrap}>
          <CloseButton className={styles.button} />
          <Menu
            items={burgerItems}
            isWithLogout={config.isWithLogout}
            isWithLanguage={config.isWithLanguage}
            flagsList={config.flagsList}
          />
        </Shutter>
      </Portal>
    </>
  );
};

export default BurgerMenu;
