import React from 'react';
import { Link } from 'react-router-dom';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { useSelectChatOpen } from '@monorepo/auth';
import { InnerHTMLSnippet } from '@monorepo/cms';
import { FooterNavigationMenu } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import PaymentsList from './components/PaymentsList';

import styles from './index.module.css';

type FooterProps = {
  menuId: number;
};

const Footer: ComponentWithConfig<FooterProps> = ({ config }) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();
  const showPaymentBlock = false;
  const isOpen = useSelectChatOpen();
  const { menuId } = config;

  return !isOpen || isDesktop ? (
    <Box className={styles.wrap}>
      <PaddingWrapper>
        <Box>
          <Box className={styles.bottom}>
            <InnerHTMLSnippet className={styles.main} html={t('footer.main')} />
            <FlexBox
              direction={FLEX_DIRECTION.row}
              align={FLEX_ALIGN.center}
              justify={FLEX_JUSTIFY.center}
              className={styles.address}
            >
              <Link to={`tel:${t('footer.phone')}`} className={styles.link}>
                {t('footer.phone')}
              </Link>
              &nbsp;
              <Link to={`mailto:${t('footer.email')}`} className={styles.link}>
                {t('footer.email')}
              </Link>
            </FlexBox>
            <InnerHTMLSnippet
              className={styles.license}
              html={t('footer.license')}
            />
            <FlexBox
              align={FLEX_ALIGN.center}
              justify={FLEX_JUSTIFY.center}
              className={styles.age}
            >
              <Icon name="age-18" className={styles.icon} />
              <InnerHTMLSnippet html={t('footer.18plus')} />
            </FlexBox>
          </Box>
          <FooterNavigationMenu menuId={menuId} />
        </Box>
      </PaddingWrapper>
      {showPaymentBlock && (
        <PaddingWrapper className={styles.listWrap}>
          <FlexBox
            justify={isDesktop ? FLEX_JUSTIFY.center : FLEX_JUSTIFY.start}
          >
            <PaymentsList className={styles.list} />
          </FlexBox>
        </PaddingWrapper>
      )}
      <PaddingWrapper className={styles.copyright}>
        <FlexBox direction={FLEX_DIRECTION.column} align={FLEX_ALIGN.center}>
          <InnerHTMLSnippet html={t('footer.copyright')} />
        </FlexBox>
      </PaddingWrapper>
    </Box>
  ) : null;
};

export default Footer;
