export enum UI_SIZES {
  xxs = 'xxs',
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl',
  xxxl = 'xxxl',
  xxxxl = 'xxxxl'
}

export enum FLEX_JUSTIFY {
  center = 'center',
  start = 'start',
  end = 'end',
  between = 'between',
  around = 'around'
}

export enum FLEX_ALIGN {
  center = 'center',
  start = 'start',
  end = 'end',
  baseline = 'baseline',
  stretch = 'stretch'
}

export enum FLEX_DIRECTION {
  row = 'row',
  column = 'column',
  rowReverse = 'rowReverse',
  columnReverse = 'columnReverse'
}

export enum FLEX_WRAP {
  nowrap = 'nowrap',
  wrap = 'wrap',
  wrapReverse = 'wrapReverse'
}

export enum BUTTON_INTENT {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  tertiaryOutlined = 'tertiaryOutlined',
  quaternary = 'quaternary',
  transparent = 'transparent',
  quinary = 'quinary',
  success = 'success',
  danger = 'danger',
  yellow = 'yellow',
  green = 'green',
  cancel = 'cancel'
}

export enum BUTTON_SIZE {
  h48 = 'h48',
  h46 = 'h46',
  h42 = 'h42',
  h40 = 'h40',
  h28 = 'h28',
  h30 = 'h30',
  h36 = 'h36'
}

export enum TEXT_SIZE {
  small = 'small',
  medium = 'medium'
}

export enum FIELD_TONE {
  light = 'light',
  dark = 'dark',
  custom = 'custom'
}

export enum SIDE_POSITION {
  left = 'left',
  right = 'right',
  center = 'center',
  wide = 'wide'
}

export enum VERTICAL_POSITION {
  top = 'top',
  bottom = 'bottom',
  middle = 'middle',
  stretch = 'stretch'
}

export enum THEME_TONE {
  default = 'default',
  switch = 'switch',
  casino = 'casino'
}

export const TOOLTIP_ID = {
  DEFAULT: 'DEFAULT'
};
