import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { EmptyState } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';
import { FLEX_DIRECTION, FlexBox, ScrollBarCustom } from '@monorepo/ui-kit';

import { useMenuRegionsData } from '../../hooks/useGetMenuRegions';
import { selectRegionsWithOrder } from '../../store/api/selectors';
import { TRegion } from '../../types';
import MenuSkeleton from '../MenuSkeleton';

import CompetitionsList from './components/CompetitionsList';
import Region from './components/Region';

import styles from './index.module.css';

type TMenuProps = {
  basePath: string;
  activeRegion: string;
  activeSport?: string;
  withScrollbar?: boolean;
};

const RegionsMenu: Component<TMenuProps> = ({
  basePath,
  activeRegion,
  activeSport,
  withScrollbar = true
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  const {
    data: regions,
    isLoading,
    isSuccess
  } = useMenuRegionsData((result) => selectRegionsWithOrder(result), {
    sportAlias: activeSport
  });

  const content = useMemo(
    () => (
      <>
        {regions.map(({ id, alias }: TRegion) => (
          <Region
            key={id}
            regionId={id}
            activeSport={activeSport}
            isActive={alias === activeRegion}
          >
            <CompetitionsList
              regionId={id}
              basePath={`${basePath}/${alias}`}
              region={alias}
              activeSport={activeSport}
            />
          </Region>
        ))}
      </>
    ),
    [regions, activeSport, activeRegion, basePath]
  );

  if (isLoading) {
    return <MenuSkeleton isWithIcons length={20} />;
  }

  if (activeSport && isSuccess && isEmpty(regions)) {
    return (
      <EmptyState
        icon={activeSport?.toLowerCase()}
        title={t('sportsbook.prematch.nocompetitions')}
        subtitle={t('sportsbook.prematch.nocompetitions_sub')}
        defaultIcon="soccer"
        className={styles.empty}
        iconClassName={styles.icon}
      />
    );
  }

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.root}>
      {withScrollbar ? (
        <ScrollBarCustom isOnlyDesktop props={{ noScrollX: true }}>
          {content}
        </ScrollBarCustom>
      ) : (
        content
      )}
    </FlexBox>
  );
};

export default RegionsMenu;
