import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getSearchString } from '@monorepo/helpers';

import { TGetSearchRequest } from '../../types/requests';
import { TGetSearchResponse } from '../../types/responses';

const searchFriendsApi = createApi({
  reducerPath: 'searchFriendsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1'
  }),
  endpoints: (build) => ({
    searchFriends: build.query<TGetSearchResponse, TGetSearchRequest>({
      query: ({ gender, maxAge, minAge }) => ({
        method: 'GET',
        url: `/public_profile/search/${gender}${getSearchString({
          max_age: maxAge,
          min_age: minAge
        })}`
      })
    })
  })
});

export const { useLazySearchFriendsQuery } = searchFriendsApi;

export default searchFriendsApi;
