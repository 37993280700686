import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useIsAuthenticated } from '@monorepo/auth';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  EllipsisText,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  useShutterRef
} from '@monorepo/ui-kit';

import { ComponentWithConfig } from '../../../../types';

import DepositModal from './components/DepositModal';

import styles from './index.module.css';

const HeaderDeposit: ComponentWithConfig = ({ config }) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { shutterRef, setShow, setHide } = useShutterRef();
  const { isAuthenticated } = useIsAuthenticated();
  const navigate = useNavigate();
  const { generalRoutes } = useSiteSettings();
  const { deposit } = generalRoutes || {};
  const handleClick = () => {
    if (isAuthenticated) {
      setShow();
    } else {
      navigate(deposit);
    }
  };
  return (
    <>
      <DepositModal
        classOverlayModal={styles.overlay}
        ref={shutterRef}
        config={config}
        handleClose={setHide}
        classContentModal={styles.modalContent}
      />
      <FlexBox
        className={styles.link}
        onClick={handleClick}
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        direction={FLEX_DIRECTION.column}
      >
        <Icon name="wallet_1" className={styles.icon} title="" />
        <EllipsisText className={styles.title}>
          {t('profile.deposit')}
        </EllipsisText>
      </FlexBox>
    </>
  );
};

export default HeaderDeposit;
