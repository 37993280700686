import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetector } from '@bc-betkit/device-detector';
import isEmpty from 'lodash/isEmpty';

import { EmptyState } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { TOURNAMENT_STATUS } from '@monorepo/tournaments';
import {
  Box,
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import CardsSkeleton from '../../../../common/CardsSkeleton';
import useGetTournaments from '../../../../hooks/useGetTournaments';

import CasinoTournament from './components/CasinoTournament';

import styles from './index.module.css';

const CasinoWidgetTournaments = () => {
  const { availableTournaments, isLoading } = useGetTournaments([
    TOURNAMENT_STATUS.UPCOMING,
    TOURNAMENT_STATUS.LIVE
  ]);

  const { t } = useI18n(NAMESPACES.CASINO);
  const { isDesktop } = useDeviceDetector();
  const itemCount = isDesktop ? 4 : 3;
  const navigate = useNavigate();
  const { generalRoutes } = useSiteSettings();
  const { casinoTournaments } = generalRoutes;
  const onViewAll = () => navigate(casinoTournaments);
  const goToTournament = (id: number) => () =>
    navigate(`${casinoTournaments}/${id}`);

  if (isLoading) {
    return (
      <Box className={styles.wrap}>
        <CardsSkeleton />
      </Box>
    );
  }

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      {isEmpty(availableTournaments) ? (
        <EmptyState
          icon="without-competitions-01"
          title={t('casino.empty.tournaments')}
          iconClassName={styles.icon}
          className={styles.boxWrapper}
        />
      ) : (
        <>
          <ul className={styles.list}>
            {availableTournaments?.map((tournament, i) => {
              const { id } = tournament;
              if (i >= itemCount) {
                return null;
              }
              return (
                <li
                  key={id}
                  className={styles.item}
                  onClick={goToTournament(id)}
                >
                  <CasinoTournament
                    tournament={tournament}
                    className={styles.tournament}
                  />
                </li>
              );
            })}
          </ul>
          <FlexBox justify={FLEX_JUSTIFY.center} className={styles.footer}>
            <Button
              intent={BUTTON_INTENT.yellow}
              className={styles.button}
              onClick={onViewAll}
              size={isDesktop ? BUTTON_SIZE.h40 : BUTTON_SIZE.h30}
            >
              {t('casino.view.all')}
            </Button>
          </FlexBox>
        </>
      )}
    </FlexBox>
  );
};

export default CasinoWidgetTournaments;
