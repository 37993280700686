import React, { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';

import { Component } from '@monorepo/type';
import { Box, FLEX_DIRECTION } from '@monorepo/ui-kit';

import {
  MARKET_TYPES,
  MARKETS_ORDER_MAP,
  OUTCOME_VIEW_MODE
} from '../../constants';
import { useLineGamesData } from '../../hooks/useGetLineGames';
import { useLineMarketsData } from '../../hooks/useGetLineMarkets';
import useLineParameters from '../../hooks/useLineParameters';
import useMiddleScreen from '../../hooks/useMiddleScreen';
import {
  selectGameBySportIdRegionIdCompetitionIdGameId,
  selectGameOrderedMarkets
} from '../../store/api/selectors';
import { TMarket } from '../../types';
import LineEmptyMarket from '../LineEmptyMarket';
import LineMarket from '../LineMarket';

import LineEmptyMarketsList from './LineEmptyMarketsList';

import styles from './index.module.css';

type Props = {
  competitionId: number;
  sportId: number;
  regionId: number;
  gameId: number;
  className?: string;
  itemClassName?: string;
  direction?: FLEX_DIRECTION;
  viewMode?: OUTCOME_VIEW_MODE;
};

const LineMarketsList: Component<Props> = ({
  className = '',
  itemClassName = '',
  competitionId,
  gameId,
  regionId,
  sportId,
  direction,
  viewMode
}) => {
  const { marketType = MARKET_TYPES.SINGLE } = useLineParameters();
  const displayKeys = useMemo(
    () => MARKETS_ORDER_MAP[marketType],
    [marketType]
  );

  const { data: game } = useLineGamesData((result) =>
    selectGameBySportIdRegionIdCompetitionIdGameId(
      result,
      sportId,
      regionId,
      competitionId,
      gameId,
      [
        'id',
        'markets_count',
        'is_blocked',
        'is_stat_available',
        'show_type',
        'sport_alias',
        'region_alias',
        'team1_name',
        'team2_name',
        'start_ts',
        'is_live'
      ]
    )
  );
  const { data: markets, isLoading } = useLineMarketsData((result) =>
    selectGameOrderedMarkets(result, gameId, displayKeys)
  );

  const middleScreen = useMiddleScreen();
  const columnShow = middleScreen ? styles.showTwo : '';

  if (isLoading) {
    return (
      <LineEmptyMarketsList
        sportAlias={game?.sportAlias}
        isFetching={isLoading}
      />
    );
  }

  if (isEmpty(markets)) {
    return null;
  }

  return (
    <Box className={`${styles.list} ${className} ${columnShow}`}>
      {markets.map((market: TMarket) => (
        <Box key={market.id || market.displayKey} className={styles.list}>
          {market.id ? (
            <>
              <LineMarket
                marketId={market.id}
                gameId={gameId}
                displayKey={market.displayKey}
                itemClassName={itemClassName}
                direction={direction}
                viewMode={viewMode}
                competitionId={competitionId}
                regionId={regionId}
                sportId={sportId}
              />
            </>
          ) : (
            <LineEmptyMarket
              sportAlias={game?.sportAlias}
              displayKey={market.displayKey}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default LineMarketsList;
