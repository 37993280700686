import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/common';

const RegisterModal = lazy(() => import('./RegisterModal'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <RegisterModal {...props} />
  </Suspense>
);
