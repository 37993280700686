import get from 'lodash/get';
import { TGetMatchInfo, TGetMatchScoresResponse } from '../../../types';

const getMatchScoreAdapter = (
  response: TGetMatchScoresResponse
): TGetMatchInfo => {
  const details = get(response, 'details', []);
  return details.reduce((acc, item) => {
    return {
      ...acc,
      [item.match_id]: {
        score: item.score,
        matchId: item.match_id,
        isFinished: item.is_match_finished
      }
    };
  }, {});
};

export default getMatchScoreAdapter;
